#solution-top-banner {
  background-color: var(--primary-color);
  width: 100%;
  min-height: 600px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

#solution-top-banner-layout {
  position: relative;
  width: 66%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 50px;
  row-gap: 15px;
}
.solution-top-banner-topic {
  text-align: center;
}
#solution-top-banner-img-galery {
  width: 100%;
  height: 34%;
  overflow: hidden;
  display: flex;
  flex-wrap: nowrap;
  column-gap: 20px;
}
.solution-top-banner-img {
  display: inline-block;
  min-width: 414px;
  height: 248px;
  border-radius: 25px;
  background-color: brown;
}
.solution-section {
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  padding-bottom: 50px;
}
.solution-section-group {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 50px;
  margin-bottom: 25px;
  padding: 0 25px;
}
.solution-card-container {
  height: 65vh;
  position: relative;
  display: flex;
  justify-content: center;
  max-height: 468px;
  margin-top: 25px;
}
.solution-card-bar {
  z-index: 0;
  width: 96%;
  height: 92%;
  background-color: var(--primary-color);
  position: absolute;
  bottom: 0;
  display: flex;
  justify-content: center;
}
.solution-card-bar-right {
  border-radius: 250px 0px 0px 250px;
  right: 0%;
}
.solution-card-bar-right .solution-card-profile {
  right: 2%;
}

.solution-card-bar-left {
  border-radius: 0px 250px 250px 0px;
  left: 0%;
}
.solution-card-bar-left .solution-card-profile {
  left: 2%;
}
.solution-card-profile {
  position: relative;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 67.5%;
  column-gap: 50px;
}
.solution-card-box {
  width: 45%;
}
.solution-card-profile-img {
  position: relative;
  bottom: 25px;
  border-radius: 25px;
  width: 40%;
  height: 105%;
  object-fit: cover;
}

.member-card-topic {
  margin-top: 50px;
  margin-bottom: 12px;
}
.row-reverse {
  flex-direction: row-reverse;
}
.topic-icon {
  margin-right: 9px;
}

.asset-sol {
  position: absolute;
}
#asset1-sol {
  left: 0;
  top: 0;
}
#asset2-sol {
  right: 0%;
  bottom: -30%;
}
#top-banner-btn-container {
  margin-top: 40px;
  display: flex;
  column-gap: 60px;
  align-items: flex-start;
  /* width: 184px; */
}
#top-banner-btn-container :nth-child(1) img:hover {
  content: url("./asset/icon/Property 1=SM2.2.svg");
}
#top-banner-btn-container :nth-child(2) img:hover {
  content: url("./asset/icon/Property 1=OM1.2.svg");
}
#top-banner-btn-container :nth-child(3) img:hover {
  content: url("./asset/icon/Property 1=FW3.2.svg");
}
#top-banner-btn-container :nth-child(4) img:hover {
  content: url("./asset/icon/Property 1=LS4.2.svg");
}
#top-banner-btn-container :nth-child(5) img:hover {
  content: url("./asset/icon/Property 1=SV5.2.svg");
}
@media only screen and (max-width: 1279px) {
  #solution-top-banner-layout {
    margin-top: 28px;
  }
  .solution-top-banner-topic {
    font-size: 36px;
  }
  .solution-top-banner-describe {
    font-size: 18px;
  }
  #top-banner-btn-container a {
    scale: 0.9;
  }
  #asset1-sol {
    display: none;
  }
  #asset2-sol {
    display: none;
  }
  #top-banner-btn-container {
    flex-wrap: wrap;
    justify-content: center;
    width: 150%;
    margin: 10px 25px;
  }

  .solution-card-profile {
    align-items: center;
    flex-direction: column;
  }
  .solution-card-profile-img {
    border-radius: 25px;
    width: 75%;
    height: 50%;
    margin-bottom: 0.62%;
    object-fit: cover;
  }
  .solution-card-box {
    width: 80%;
  }
  .member-card-topic {
    margin-top: 25px;
  }
  .member-card-describe {
    margin-top: 15px;
    margin-bottom: 15px;
  }
  .solution-card-container {
    align-items: flex-start;
    height: 50vh;
    min-height: 580px;
    margin-top: 25px;
  }
  .solution-card-bar {
    bottom: 0px;
  }
}
@media only screen and (max-width: 860px) {
  .solution-card-bar {
    width: 100%;
    height: 100%;
  }
  .solution-card-bar-right,
  .solution-card-bar-left {
    border-radius: 0%;
    top: 0;
  }
  .solution-card-profile {
    justify-content: flex-start;
    margin-top: 16px;
    width: 80%;
  }
}
@media only screen and (max-width: 520px) {
  #solution-top-banner {
    min-height: auto;
    height: 30%;
  }
  .solution-top-banner-describe br {
    display: none;
  }
  .solution-top-banner-topic {
    font-size: 18px;
  }
  .solution-top-banner-topic br {
    display: none;
  }
  .solution-top-banner-describe {
    font-size: 12px;
  }
  #top-banner-btn-container a img {
    width: 50px;
    height: auto;
  }
  #top-banner-btn-container {
    flex-wrap: nowrap;
    overflow-x: scroll;
    margin: 0px;
    column-gap: 15px;
  }
  #solution-top-banner-layout {
    width: 74.44%;
    margin-bottom: 0px;
    row-gap: 15px;
  }

  .solution-card-profile {
    position: static;
    width: 80%;
  }
  .solution-card-profile-img {
    position: static;
    height: 198px;
    border-radius: 15px;
    width: 100%;
    object-fit: cover;
  }
  .solution-card-box {
    width: 100%;
  }
  .member-card-topic {
    margin-top: 5px;
    font-size: 20px;
  }
  .member-card-describe {
    font-size: 12px;

    margin-top: 15px;
    margin-bottom: 15px;
  }
  .solution-card-container {
    min-height: auto;
    height: auto;
    max-height: none;
  }

  .solution-section-group {
    margin-top: 20px;
    margin-bottom: 0px;
  }
  .solution-section {
    padding-bottom: 0;
  }
  .solution-section-group :nth-child(1) {
    font-size: 20px;
  }
  .solution-section-group :nth-child(2) {
    text-align: center;
    padding: 15px 15px 0 15px;
    font-size: 12px;
  }
  .solution-card-bar {
    width: 100%;
    padding-bottom: 12px;
    height: auto;
    position: relative;
  }
  .solution-card-bar-right {
    border-radius: 0 185px 0 0;
  }
  .solution-card-bar-left {
    border-radius:  185px 0 0 0;
  }
  .solution-container{
    padding-bottom: 20px;
  }
}
