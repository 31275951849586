/* 
$background: #e74c3c;
$select-color: #fff;
$aselect-background: #c0392b;
$select-width: 220px;
$select-height: 40px;  */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #26262685; 
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: #b9b9b946; 
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #838383; 
}
.select-hidden {
  display: none;
  visibility: hidden;
  padding-right: 10px;
}
ul.select-options :nth-child(1) {
  display: none !important;
}
.select-options {
  width: 300px;
}
.select-options li:hover {
  color: var(--primary-color);
}
.select {
  cursor: pointer;
  width: 36%;
  width: 300px;
  /* appearance: none; */
  text-transform: uppercase;
  height: auto;
  flex-grow: 2;
}
.select-styled {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  padding-right: 50px;
}

.select-options {
  display: none;
  position: absolute;
  z-index: 999;
  margin: 0;
  padding: 0;
  list-style: none;
  margin-top: 15px;

  flex-direction: column;
  row-gap: 8px;
  max-height: 100px;
  overflow-y: scroll;
}
li {
  margin: 0;
}
