.section {
  position: relative;
  display: flex;
  justify-content: center;
  width: 100%;
}
#home-cover {
  display: none;
  padding: 0 75px;
  width: 100%;
  height: 100%;
  top: 0;
  background-color: rgba(59, 19, 218, 0.55);
  position: absolute;
  z-index: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: start;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}
#home-cover :nth-child(2) {
  color: #5fffff;
}
.partner-banner {
  height: 20vh;
}
.asset {
  z-index: -1;
  position: absolute;
  background-repeat: no-repeat;
}
#top-banner {
  width: 100%;
  background-image: url("./asset/BG01.png");
  background-attachment: scroll;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  text-align: center;
}
.text-container {
  margin-top: 44px;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}
.strategy-container {
  display: flex;
  flex-direction: column;
  row-gap: 20px;
  align-items: center;
  margin-top: 80px;
  width: 100%;
  padding-bottom: 48px;
}
.strategy-container .studio {
  margin-top: 40px;
  padding-bottom: 0;
}
#company-box {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 78%;
  height: 72%;
  max-width: 991px;
  max-height: 608px;
}
#strategy-decribe {
  width: 66%;
  display: flex;
  justify-content: space-between;
  column-gap: 65px;
}
#studio-decribe {
  width: 66.25%;
  display: flex;
  justify-content: center;
  column-gap: 40px;
}

#strategy-decribe-textbox {
  display: flex;
  flex-direction: column;
  row-gap: 15px;
  width: 100%;
}
#strategy-decribe-textbox :nth-child(1) {
  text-align: center;
}
.asset-1 {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 300px;
  height: 300px;
  transform: rotate(15.926deg);
  background: url("asset/Asset\ 1.png"), cover no-repeat;
}
.asset-2 {
  position: absolute;
  top: 0;
  right: 0;
  width: 300px;
  height: 300px;
  transform: rotate(-148.505deg);
  flex-shrink: 0;
  background: url("asset/Arrow\ 2.svg"), 50% / cover no-repeat;
}
#bar-1 {
  position: absolute;
  top: 81px;
  left: -16%;
  width: 52%;
  height: 12%;
  flex-shrink: 0;
  border-radius: 0px 50px 50px 0px;
  background: #3b13da;
  max-height: 100px;
}
#bar-2 {
  max-height: 100px;
  position: absolute;
  right: 0%;
  top: 59%;
  width: 25%;
  height: 12%;
  flex-shrink: 0;
  border-radius: 50px 0px 0px 50px;
  background: #3b13da;
}
#bar-3 {
  max-height: 100px;
  position: absolute;
  left: -20%;
  bottom: 5%;
  width: 30%;
  border-radius: 0px 50px 50px 0px;
  height: 12%;
  flex-shrink: 0;
  background: #3b13da;
}
#strategy-content2 {
  display: flex;
  justify-content: center;
  flex-direction: row;
  width: 100%;
}
.home-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}
#home-box {
  height: 100%;
  width: 75%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.home-card-box {
  width: 66%;
  display: flex;
  justify-content: center;
  column-gap: 20px;
}
.home-card {
  height: min-content;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: flex-start;
}
.sub-card {
  border-radius: 20px;
  padding: 15px 24px 24px 24px;
  height: 100%;
}
.home-card-image {
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  height: 60%;
  object-fit: cover;
  object-position: top;
  width: 100%;
  border-radius: 10px;
}
.home-studio {
  height: 100%;
}
.home-card-topic {
  margin-top: 16px;
  margin-bottom: 9px;
}

.home-link {
  color: #3b13da;
  height: 30px;
  text-decoration: none;
  display: flex;
  align-items: center;
  gap: 12px;
}
.home-link-box:hover .home-link span {
  color: #9747ff;
}
.home-link-box:hover .home-link img {
  content: url("asset/Arrow\ 2.svg");
}

.partner {
  height: fit-content;
  min-height: 322px;
}
#partner-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}
#partner-topic {
  margin-top: 48px;
  margin-bottom: 38px;
}
#partner-box {
  width: fit-content;
  display: flex;
  flex-direction: column;
  justify-content: center;
  row-gap: 25.76px;
  margin-bottom: 66px;
}
#partner-line {
  padding: 10px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  column-gap: 40px;
  row-gap: 25px;
}

#partner-konvy-icon {
  position: relative;
  top: 8px;
}
.circle-asset {
  position: absolute;
  z-index: 1;
  height: 63%;
  top: 50%;
}
#circle-asset1 {
  left: 0;
  transform: translate(0%, -50%);
}
#circle-asset2 {
  right: 0;
  transform: translate(0%, -50%);
}
.card-topic {
  display: flex;
  align-items: center;
  column-gap: 10px;
  margin-bottom: 12px;
}
#section-hiring {
  background-image: url("./asset/pic7.jfif");
  width: 100%;
  height: 351px;
  /* min-height: 351px; */
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}
#section-hiring a {
  text-decoration: none;
}

#apply-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 14px 24px;
  column-gap: 10px;
  text-decoration: none;
}
.hiring-cover {
  background: #3b13da47;
  position: relative;
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}
#strategy-decribe-img {
  width: 24vw;
}
.home-solution-box #strategy-decribe-textbox {
  background-color: white;
}
#home-cover {
  display: none;
}
@media only screen and (max-width: 1440px) {
  #home-cover {
    display: none;
  }
  #bar-1 {
    left: -10%;
  }
}
@media only screen and (max-width: 1360px) {
  #bar-1 {
    top: 1%;
    left: -60%;
  }
}
@media only screen and (max-width: 1279px) {
  
  .section {
    overflow: hidden;
  }
  #strategy-decribe-img {
    width: 50vw;
    height: 40vw;
  }
  .expand {
    width: 100%;
  }
  #bar-1 {
    width: 40%;
  }
  #bar-2 {
    right: 0%;
  }
  #circle-asset1 {
    left: -10%;
  }
  #circle-asset2 {
    right: -10%;
  }
  #studio-decribe {
    flex-direction: column;
  }
  #strategy-decribe-textbox {
    width: 100%;
  }
  #partner-box{
    margin-bottom: 30px;
  }
}
@media only screen and (max-width: 860px) {
  #partner-line {
    column-gap: 15px;
    padding: 18px;
  }
  #section-hiring{
    height: 117px;
  }
  .hiring-cover :nth-child(1){
    font-size: 20px;
  }
  .partner{
    min-height: auto;
  }
  #partner-line img {
    height: 20px;
    width: auto;
    row-gap: 15px;
    column-gap: 15px;
  }
  .strategy-container{
    margin-top: 20px;
    padding-bottom: 35px;
  }
  #home-cover {
    display: flex;
  }
  #home-cover span{
    text-align: center;
  }
  #strategy-decribe-img{
    display: none;
  }
  .text-container span {
    font-size: 48px;
    display: none;
  }
  #strategy-decribe-img {
    width: 50vw;
    height: 40vw;
  }
  #strategy-decribe {
    flex-direction: column;
  }
  #strategy-decribe-textbox :nth-child(1) {
    font-size: 20px;
    width: 100%;
  }
  #strategy-decribe-textbox :nth-child(2) {
    font-size: 15px;
    width: 100%;
  }
  .home-card-topic {
    font-size: 20px;
  }
  .home-card-topic + div {
    font-size: 12px;
  }
  .card-topic{
    font-size: 20px;
  }
  .card-topic + div{
    font-size:12px;
  }
  .sub-card{
    padding: 10px 18px;
  }
  .studio #strategy-decribe-textbox :nth-child(1) {
    font-size: 24px;
    width: 100%;
  }
  .studio #strategy-decribe-textbox :nth-child(2) {
    font-size: 18px;
    width: 100%;
  }

  #bar-1 {
    width: 70%;
  }
  #bar-2 {
    top: 32%;
  }

  #link-service {
    font-size: 16px;
  }
  #bar-3 {
    left: -8%;
    bottom: 38%;
  }
  .home-card-box {
    /* width: 100%; */
    flex-direction: column;
    row-gap: 20px;
  }
  .circle-asset {
    height: 45%;
  }
  #circle-asset1 {
    left: -20%;
  }
  #circle-asset2 {
    right: -20%;
  }
  #partner-topic {
    font-size: 20px;
    margin-top: 25px;
    margin-bottom: 0px;
    text-align: center;
  }
  #apply-btn {
    justify-content: center;
    padding: 1px 5px;
    column-gap: 5px;
    text-decoration: none;
    font-size: 14px;
}
}
