.navbar {
  z-index: 99;
  height: var(--nav-space);
  width: 100%;
  position: fixed;
  top: 0%;
  background: #fff;
  box-shadow: 0px 7px 35px 0px rgba(0, 0, 0, 0.19);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 123px;
}
.nav-box {
  margin-left: 40px;
  display: flex;
  column-gap: 31px;
  align-items: center;
}
.nav-link {
  text-decoration: none;
  color: black;
}
#contact {
  padding: 5px 7px;
  color: #fff;
  background-color: var(--primary-color);
  border-radius: 11px;
}
.nav-link:hover {
  color: #5fffff;
}
#contact:hover {
  background-color: #5fffff;
}
.selected-page {
  color: var(--primary-color);
}
.selected-page-v2 {
  color: black !important;
  background-color: var(--third-color) !important;
}
.selected-page-v2:hover {
  color: rgb(255, 255, 255) !important;
  background-color: var(--third-color) !important;
}
#nav-collapse {
  display: none;
  width: 30px;
  height: 14px;
  flex-direction: column;
  justify-content: space-between;
  z-index: 99;
}
.bar-btn{
  width: 100%;
  height: 4px;
  border-radius: 2px;
  background-color: var(--primary-color);
  transform-origin: left;
}
.nav-expanded{
  height: 24px !important;
  width: 24px !important;
}
.nav-expanded div{
  width: 29px;
}
.nav-expanded div:nth-child(1){
  transform: rotate(45deg);
}
.nav-expanded div:nth-child(2){
  transform: rotate(-45deg);
}
@keyframes fadeIn {
  0% { opacity: 0; }
  100% { opacity: 1; }
}
#nav-collapse-box {
  animation: fadeIn 0.3s;
  display: none;
  position: absolute;
  left: 0%;
  top:0;
  background-color: white;
  flex-direction: column;
  width: 96%;
  border-radius: 0 30px 30px 0;
  padding: 144px 55px;
  height: 100vh;
  row-gap: 35px;
}
.nav-link-collapse {
width: fit-content;
}

@media only screen and (max-width: 860px) {
  .nav-box {
    display: none;
  }
  #contact {
    border-radius: 0px;
    padding:10px 20px;
    gap: 10px;
  }
  #contact div{
    display: flex;
    column-gap: 10px;
  }
  .nav-link {
    color: var(--primary-color);
  }
  .navbar {
    padding: 25px;
  }
  :root {
    --nav-space: 57px;
  }

  #app-logo {
    width: 67px;
    height: 17px;
  }
  
  #nav-collapse{
    display: flex;
  }
}
