#about-top-banner {
  background-image: url(asset/about_bg.svg);

  width: 100%;
  margin: 0;
  position: relative;
  background-size: cover;
  background-position: center;
}
#top-banner-text-box {
  position: absolute;
  right: 0;
  bottom: 22%;
  width: 630px;
  height: 266px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: start;
}
#top-banner-text-box-layout {
  margin: 24px 108px;
}
#member-section {
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  padding: 20px 0;
}
.member-card-container {
  height: 65vh;
  position: relative;
  display: flex;
  justify-content: center;
}
.member-card-bar {
  z-index: -1;
  width: 96.4%;
  height: 76.66%;
  background-color: var(--primary-color);
  position: absolute;
  margin-top: 88px;
}
#member-card-bar-right {
  border-radius: 250px 0px 0px 250px;
  right: 0%;
}
#member-card-bar-left {
  border-radius: 0px 250px 250px 0px;
  left: 0%;
}
.member-card-profile {
  position: relative;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 67.5%;
  column-gap: 50px;
}
.member-card-name {
  margin-top: 30px;
}
.member-card-profile-img {
  border-radius: 25px;
  width: 30%;
  min-width: 354px;
  height: 80%;
  margin-bottom: 4.62%;
  object-fit: cover;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}
.member-card-box {
  max-width: 412px;
  display: flex;
  flex-direction: column;
  row-gap: 15px;
}

.row-reverse {
  flex-direction: row-reverse;
}

@media only screen and (max-width: 1279px) {
  .member-card-profile {
    position: relative;
    height: 85%;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 67.5%;
    column-gap: 50px;
    margin-bottom: 30px;
  }
  .member-card-container {
    height: 50vh;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: flex-end;
  }
  .member-card-box {
    max-width: 412px;
    width: 60%;
  }
  .member-card-box :nth-child(1) {
    font-size: 36px;
  }
  .member-card-box :nth-child(2) {
    font-size: 24px;
  }
  .role {
    font-size: 14px ;
  }
  .member-card-bar {
    margin-top: 0px;
    bottom: 5%;
  }
  .member-card-profile-img {
    object-position: top center;
  }
}
@media only screen and (max-width: 520px) {
  #top-banner-text-box {
    right: 0;
    top:84px;
    width: 85%;
    height: 205px;
  }
  #top-banner-text-topic {
    text-align: start;
    font-size: 20px;
    margin-bottom: 15px;
  }
  #top-banner-text-box-layout {
    margin: 10px 15px;
  }
  .row-reverse {
    flex-direction: column !important;
  }
  .member-card-profile {
    height: 85%;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 85%;
    margin-bottom: 30px;
  }
  .member-card-profile-img {
    border-radius: 25px;
    width: 40%;
    min-width: 248px;
    height: 60%;
    margin-bottom: 5px;
    object-fit: cover;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  }
  .member-card-box {
    max-width: none;
    width: 75%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    row-gap: 10px;
  }
  .member-card-box :nth-child(1) {
    font-size: 20px;
    margin-top: 0px;
  }
  .member-card-box div:nth-child(1) :nth-child(2) {
    font-size: 20px;
    margin-top: 0px;
  }
  .member-card-box :nth-child(2) {
    font-size: 12px;
    margin-top: 0px;
  }
  .member-card-box :nth-child(3) {
    font-size: 12px;
    margin-top: 0px;
  }
  #top-banner-text {
    font-size: 12px;
  }
  .member-card-container {
    height: fit-content;
    margin: 15px 0;
  }
  .member-card-bar {
    width: 100%;
    height: 95%;
}
#member-card-bar-right{
  border-radius: 250px 0 0 0;
}
#member-card-bar-left{
  border-radius:  0 250px 0 0;
}
}
