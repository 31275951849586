#careers-buttons {
  display: flex;
  column-gap: 25px;
  flex-wrap: wrap;
  row-gap: 15px;
}
#find-careers {
  border-bottom: #e1dada 2px solid;
  width: 100%;
  padding: 0 8%;
  row-gap: 15px;
  padding-top: 37px;
  padding-bottom: 15px;
}
#career-container {
  width: 100%;
  padding: 0 8%;
  display: flex;
  justify-content: space-between;
  margin: 25px 0px;
}
#careers-buttons {
  margin-top: 15px;
}
.department-selected {
  color: #3b13da;
  font-family: "Montserrat", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
#career-content {
  width: 70%;
  height: 500px;
  padding: 10px;
}
#career-filter {
  display: flex;
  flex-direction: column;
  width: 16%;
  min-width: 178px;
}
#filter-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
#clear-filter-Btn {
  border: #5e5a5a 1px solid;
  padding: 6px 7px;
}
#clear-filter-Btn:hover {
  cursor: pointer;
  color: var(--primary-color);
  border: var(--primary-color) 1px solid;
}
.filter-box {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 15px;
}
.filter-box img:hover {
  cursor: pointer;
}
#department-filter,
#city-filter {
  display: flex;
  flex-direction: column;
  row-gap: 10px;
  margin-top: -100%;
  transition: all 0.7s;
}
.expanded-filter {
  /* display: flex !important; */
  margin-top: 22px !important;
}
.filter-container {
  overflow: hidden;
}
.filter-item-box {
  display: flex;
  align-items: center;
  column-gap: 12px;
}
#jobs-box {
  margin-top: 25px;
  display: flex;
  flex-direction: column;
  color: #5e5a5a;
  row-gap: 27px;
}
.job-box {
  display: flex;
  justify-content: space-between;
  padding-bottom: 26px;
  border-bottom: 1px solid #d3cccc;
}
.job-box:hover {
  box-shadow: black 0px 1px;
  cursor: pointer;
}
.job-data {
  color: #817c7c;
  display: flex;
  justify-content: space-between;
  width: fit-content;
  column-gap: 10px;
  align-items: center;
}
.job-hire-date {
  width: 30%;
  padding-top: 7px;
  color: #817c7c;
}
.job-detail {
  width: 70%;
}
.job-detail-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 83%;
  min-height: 50vh;
}
.job-detail-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 25px 0;
}
#career-apply-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 14px 24px;
  column-gap: 10px;
  text-decoration: none;
  width: 245px;
  height: 57px;
}
.job-box-2 {
  display: flex;
  justify-content: space-between;
  padding-bottom: 26px;
  border-bottom: 1px solid #d3cccc;
  width: 100%;
  padding: 25px 0;
}
.job-box-2:hover {
  box-shadow: black 0px 1px;
  cursor: pointer;
}
.responsibilities-text-describe {
  padding: 5px 10px;
}
.job-detail-textbox {
  width: 100%;
}
@media only screen and (max-width: 520px) {
  #careers-buttons {
    flex-wrap: nowrap;
    overflow-x: scroll;
    padding-bottom: 7px;
  }
  #career-filter {
    display: none;
  }
  #career-content {
    width: 100%;
  }
  .amount-job {
    font-size: 20px;
  }
  .job-name {
    font-size: 17px;
  }
  .job-data,
  .job-hire-date {
    font-size: 12px;
  }
  .job-hire-date {
    width: 100%;
  }
  .job-box {
    flex-direction: column;
    row-gap: 15px;
    padding: 5px;
  }
  .job-detail {
    width: 100%;
  }
  .find-text {
    font-size: 20px;
  }
  #careers-buttons {
    font-size: 14px;
  }
  .job-detail-textbox div:nth-child(1) {
    font-size: 14px;
  }
  .job-detail-textbox div:nth-child(2),
  .job-detail-textbox ul {
    font-size: 12px;
  }
  #career-apply-btn {
    padding: 5px 5px;
    width: 145px;
    height: 40px;
    font-size: 12px;
    font-weight: 600;
  }
  #career-apply-btn img {
    width: 20px;
    height: 20px;
  }
}
