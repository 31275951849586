#contact-page-layout {
  margin-top: var(--nav-space);
  padding: 0px;
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
}
@media only screen and (max-width: 1280) {
  #contact-section-form {
    background-color: aqua;
  }
}
#contact-section-form {
  position: relative;
  display: flex;
  justify-content: flex-end;
  height: calc(100vh - 100px);
  min-height: 610px;
}
#contact-box {
  width: 91.56%;
  height: 90%;
  display: flex;
  justify-content: flex-end;
  column-gap: 50px;
  margin-top: 50px;
}
#contact-topic-box {
  display: flex;
  flex-direction: column;
  row-gap: 15px;
  margin-bottom: 48px;
}
#contact-img {
  width: 50%;
  height: 100%;
  min-width: 250px;
  background-image: url("./asset/47485a9415b2caf75294f61be254639e.jfif");
  background-size: cover;
  background-position: 50% -20%;
  background-repeat: no-repeat;
}
#contact-img-cover {
  width: 100%;
  height: 100%;
  background-color: rgba(84, 50, 219, 0.24);
}
#contact-section-location {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 50px;
}
#location-container {
  height: 50%;
  width: 83%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-bottom: 60px;
}
#location-tab {
  height: 45px;
}
#location-box {
  margin: 35px 0 24px 0;
  display: flex;
  justify-content: space-between;
}

#contact-form {
  display: flex;
  flex-direction: column;
  row-gap: 15px;
}
#contact-form div {
  display: flex;
  column-gap: 16px;
}
#contact-form div div {
  display: flex;
  flex-direction: column;
  row-gap: 8px;
}
#contact-form div input {
  height: 32px;
  width: 253px;
}
#contact-form div:nth-child(3) div,
#contact-form div:nth-child(4) div {
  width: 100%;
}
.requireStar {
  color: red;
}
#contact-btn-box {
  width: 100%;
  display: flex;
  flex-direction: row-reverse;
}
#contact-btn {
  background-color: var(--primary-color);
  color: var(--secondary-color);
  width: 241px;
  height: 57px;
  border: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 10px;
  position: absolute;
  bottom: 0;
  right: 0;
}
#contact-btn:hover {
  cursor: pointer;
}
#company,
#message {
  width: 100% !important;
}
#message {
  height: 92px !important;
  resize: none;
}
#location-tab-box {
  width: 100%;
  border-bottom: 2px solid #e1dada;
  display: flex;
  justify-content: center;
}
#location-tab {
  width: 83%;
  display: flex;
}
.tab {
  border-bottom: 6px solid #5e5a5a;
}
#location-address {
  display: flex;
  flex-direction: column;
  margin-top: 5px;
}

.contact-asset {
  top: 50%;
  transform: translateY(-50%);
  z-index: -1;
  position: absolute;
  left: 0;
}
#contact-box-layout {
  position: relative;
  background-color: white;
}
.contact-locaion {
  display: flex;
  column-gap: 5px;
  flex-direction: column;
  row-gap: 3px;
}
.contact-locaion div{
  display: flex;
  align-items: center;
  column-gap: 5px;
}
@media only screen and (max-width: 1279px) {
  #contact-topic-box {
    margin-bottom: 14px;
  }
  #contact-box {
    justify-content: center;
  }
}
@media only screen and (max-width: 760px) {

  .contact-asset{
    height: 300px;
    width: 300px;
    left: -20%;
    top: 16%;
  }
  #contact-img{
    height: 300px;
    width: 300px;
    position: relative;
    right: -25%;
  }
  #contact-topic-box {
    margin-bottom: 14px;
  }
  #contact-topic-box :nth-child(1) {
    font-size: 16px;
  }
  #contact-topic-box :nth-child(2) {
    font-size: 24px;
  }
  #contact-box {
    width:  80%;
    flex-direction: column-reverse;
    row-gap: 40px;
    margin-top: 20px;
  }

  #contact-section-form {
    justify-content: center;
    height: auto;
  }
  #contact-form > div {
    row-gap: 15px;
    flex-direction: column;
  }
  #contact-form div div input {
    width: 100%;
  }
  #location-box {
    flex-direction: column;
  }
  #location-box :nth-child(1) {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .tab{
    font-size: 20px;
  }
  #location-box > div:nth-child(1) {
    font-size: 20px;
    margin-bottom: 15px;
  }
  #location-box {
    height: auto;
    margin: 15px 0 24px 0;
    display: flex;
    justify-content: space-between;
  }
  #contact-btn {
    position: static;
  }
  #contact-btn-box {
    display: flex;
    justify-content: center;
    margin: 20px 0 20px 0;
  }
  .contact-locaion{
    margin-top: 15px;
    font-size: 12px;
  }
  #location-map{
    height: 25%;
  }
  #location-address,#location-address div{
    margin-top: 0;
    font-size: 12px;
  }
}
