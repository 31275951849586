#careers-top-banner {
  background-image: url("./asset/e68ac4ead884408b96114d26675f8df2.jfif");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
}
#careers-content-box {
  position: absolute;
  bottom: 18%;
  left: 9%;

  display: flex;
  flex-direction: column;
  row-gap: 42px;
}
#careers-text {
  text-shadow: 9px 9px 4px rgba(0, 0, 0, 0.25);
}
#carreers-form {
  display: flex;
  /* flex-wrap: wrap; */
}
.bg-transparent {
  background-color: transparent;
  border: none;
}
#careers-select-box {
  width: min-content;
  display: flex;
  column-gap: 60px;
  padding-bottom: 6px;
  border-bottom: 2px solid white;
  margin-right: 30px;
  /* flex-wrap: wrap; */
  width: 100%;
}
.careers-select {
  background-image: url(./asset/Arrow\ 3.svg);
  background-repeat: no-repeat;
  width: 36%;
  min-width: 300px;
  appearance: none;
  background-position: calc(100%) calc(80%);
  text-transform: uppercase;
}
.cereers-select-option {
  background-color: transparent;
  border: none;
  text-transform: uppercase;
}
.hide {
  display: none;
}

#careers-family-section {
  background-color: rgb(252, 252, 252);
  min-height: 530px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  row-gap: 30px;
  position: relative;
}
#careers-family-section :nth-child(1) {
  z-index: 1;
}
#member-box {
  display: flex;
  column-gap: 20px;
  row-gap: 20px;
  flex-wrap: wrap;
  justify-content: center;
  z-index: 1;
}
.family-member {
  object-fit: cover;
  border-radius: 16px;
  width: 100%;
  height: 100%;
}
#careers-family-section .asset {
  z-index: 0;
  position: absolute;
  background-repeat: no-repeat;
}
#asset1 {
  width: 46px;
  height: 46px;
  background-image: url(./asset/Ellipse\ 31.png);
  top: 80px;
  left: 86px;
}
#asset2 {
  width: 320px;
  height: 250px;
  background-image: url(./asset/Subtract1.png);
  left: 0%;
  bottom: 0%;
}
#asset3 {
  width: 275px;
  height: 300px;
  background-image: url(./asset/Subtract2.png);
  top: 0;
  right: 0;
}
#asset4 {
  width: 24px;
  height: 24px;
  background-image: url(./asset/Ellipse\ 44.png);
  bottom: 29px;
  right: 185px;
}
.member-cover {
  display: none;
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: #ffffffa6;
  border-radius: 16px;
  transition: background-color 1.2s ease;
  padding: 35px 15px;
}
.member-card {
  position: relative;
  width: 196px;
  height: 321px;
  border-radius: 16px;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}
.member-info {
  display: flex;
  width: 85%;
  flex-direction: column;
  row-gap: 7px;
  position: absolute;

}
.member-card:hover .member-cover {
  display: flex;
  flex-direction: column-reverse;
}

.member-cover:hover {
  background-color: #4a1efea6;
}
@media only screen and (max-width: 560px) {
  #careers-top-banner {
    background-image: url("./asset/e68ac4ead884408b96114d26675f8df2.jfif");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;

    height: 30vh;
  }
  #careers-text {
    font-size: 24px;
  }
  .select-styled {
    font-size: 16px;
  }
  #careers-content-box {
    position: absolute;
    bottom: 18%;
    left: 5%;
    right: 5%;
    display: flex;
    flex-direction: column;
    row-gap: 8px;
  }
  #careers-select-box {
    column-gap: 10px;
    margin-right: 5px;
  }
  .select:nth-child(1) .careers-select {
    width: 100px;
    min-width: auto;
  }
  .select:nth-child(2) .careers-select {
    width: 200px;
    min-width: auto;
  }
  .select {
    width: auto;
  }
  #carreers-form a {
    height: 25px;
    display: flex;
    align-items: center;
  }
  #carreers-form a img {
    width: 40px;
    height: 25px;
  }
  .select-options {
    background-color: #3b13dac6;
    padding: 5px 5px;
    width: auto;
    border-radius: 4px;
    margin-top: 8px;
  }
  #careers-family-section {
    padding: 20px 0;
    min-height: auto;
  }
  #careers-family-section :first-child {
    font-size: 20px;
  }
  .member-card,
  .member-card img {
    width: 128px;
    height: 210px;
  }
  #asset1 {
    width: 28px;
    height: 28px;
    background-size: cover;
    top: 38%;
    left: 15px;
  }
  #asset2 {
    background-size: cover;
    width: 35%;
    height: 35%;
    bottom: 0;
    left: 0;
  }
  #asset3 {
    background-image: url(./asset/Subtract.svg);
    width: 78px;
    height: 100%;
    top: 0;
    right: -6%;
  }
  #asset4 {
    background-size: cover;
    width: 15px;
    height: 15px;
    bottom: 45%;
    right: 15px;
  }

  .member-info div:nth-child(2) {
    font-size: 12px;
  }
  .member-cover{
    padding: 20px 10px;
  }
}
