@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@500;600;700;800&display=swap');

* {
  box-sizing: border-box;
  max-width: 100vw;
  transition: all .3s ease;
}
html,body {
  margin: 0;
  scroll-behavior: smooth;
}
:root {
  --primary-color: #3b13da;
  --secondary-color: #ffffff;
  --third-color:#5FFFFF;
  --nav-space:100px;
  user-select: none;
}
.main-font {
 
  font-family: "Montserrat", sans-serif;
}
.font-10 {
  font-size: 10px;
}
.font-12 {
  font-size: 10px;
}
.font-14{
  font-size: 14px;
}
.font-16 {
  font-size: 16px;
}
.font-18 {
  font-size: 18px;
}
.font-20 {
  font-size: 20px;
}
.font-24 {
  font-size: 24px;
}
.font-30 {
  font-size: 30px;
}
.font-32 {
  font-size: 32px;
}
.font-36 {
  font-size: 36px;
}
.font-40 {
  font-size: 48px;
}
.font-46 {
  font-size: 46px;
}
.font-48 {
  font-size: 48px;
}
.font-50 {
  font-size: 50px;
}
.font-75 {
  font-size: 75px;
}
.font-62 {
  font-size: 62px;
}
.font-64 {
  font-size: 64px;
}
.font-73 {
  font-size: 73px;
}
.font-96 {
  font-size: 96px;
}
.color-primary {
  color: var(--primary-color);
}
.color-secondary {
  color: var(--secondary-color);
}
.color-third{
  color: var(--third-color);
}
.color-4th{
  color: black;
}
.color-5th{
  color: #5E5A5A;
}

.bold-500 {
  font-weight: 500;
}
.bold-600 {
  font-weight: 600;
}
.bold-700 {
  font-weight: 700;
}
.bold-800 {
  font-weight: 800;
}
.bg-primary {
  background-color: var(--primary-color);
}

.color-third{
  color: #5FFFFF;
}
.layout{
  margin-top: var(--nav-space);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  font-size: 75px;
  color: #3b13da;
  overflow: hidden;
}
.main-container {
  width: 100%;
  height: fit-content;
}
.top-banner{
  height: calc(100vh - var(--nav-space));
}
.top-banner-cover {
  background: #3b13da47;
  position: relative;
  width: 100%;
  height: 100%;
}
.align-center{
  text-align: center;
}