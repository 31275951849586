#footer-container {
  min-height: 386px;
  background-color: var(--primary-color);
  width: 100%;
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
}
.footer-box {
  max-width: 390px;
}
.footer-social-box {
  margin-top: 12px;
  display: flex;
  height: 34px;
  align-items: center;
  gap: 11px;
}
.footer-social-box a{
  height: 100%;
  display: flex;
  align-items: flex-end;
}
#footer-icon {
  margin-top: 52px;
  margin-bottom: 0px;
}
#ripples-co{
  margin-bottom: 10px;
  letter-spacing: 1px;
}
#footer-social {
  margin-top: 68px;
}
#footer-address-box {
  margin-top: 86px;
  margin-bottom: 35px;
}
#footer-address {
  margin-top: 16px;
}
#footer-contact {
  margin-top: 13px;
}
#footer-link-box {
  display: flex;
  flex-direction: column;
  row-gap: 13px;
}
.footer-link {
  text-decoration: none;
}
#footer-navigate {
  margin-top: 86px;
  margin-bottom: 16px;
}
.show-860{
  display: none !important;
}
@media only screen and (max-width: 1279px) {
  #footer-container .footer-box:nth-child(3) {
    display: none ;
  }
  #footer-address-box {
    margin-top: 52px;
  }
  .show-860{
    display: none ;
  }
}
@media only screen and (max-width: 860px) {
  .footer-box {
    width: 90%;
  }
  #footer-container .footer-box:nth-child(3) {
    display: none;
  }
  #footer-address-box {
    margin-top: 16px;
    margin-bottom: 15px;
  }
  #footer-address-box :first-child {
    font-size: 20px;
  }
  .footer-box div:nth-child(2) {
    font-size: 20px;
  }
  #footer-address :nth-child(1) {
    font-size: 13px;
  }
  #footer-contact,#footer-contact a{
    font-size: 12px;
  }
  #footer-address {
    font-size: 12px;
    margin-top: 10px;
  }
  #footer-social {
    margin-top: 15px;
  }
  #footer-icon {
    margin-top: 14px;
    margin-bottom: 0px;
    width: 150px;
    height: 38px;
  }
  .hide-860 {
    display: none !important;
  }
  #footer-container {
    min-height: auto;
  }
  .show-860{
    display: flex !important;
  }
  .footer-social-box{
    height: auto;
    margin-bottom: 30px;
  }
  #footer-social{
    font-size: 20px;
    margin-top: 25px;
  }
}
